a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}


.navbar {
  background-color: #002061;  /* nieuwe achtergrondkleur */
  border-bottom: 2px solid #abcdef; /* nieuwe rand */
  height: 86px;
  margin-left: 690px; 
  padding-left: 20px; 

}

.navbar-collapse {
  margin-left: 50px;
}

/* Voor het logo */
.navbar-brand {
  background-color: white !important;
  margin-left: -290px;
}

.navbar-brand img {
  height: 50px;
  margin-right: 10px;
}

/* Voor de menu-items */
.navbar-nav .nav-link {
  color: white;
  font-family: "Rubik", Sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.navbar-nav .nav-link:hover {
  color: lightgray; /* voor een hover-effect */
}