/* CSS */
.stats-container {
    font-family: "Rubik", Sans-serif;
    color: #081F5D; /* Kleur van de tekst */
    border-radius: 5px; /* Ronde hoeken */
    padding: 20px; /* Ruimte binnenin de container */
    display: flex; /* Gebruik flexbox voor de container */
    justify-content: space-around; /* Verdeel de ruimte gelijk rond de items */
    align-items: center; /* Centreer items verticaal */
    flex-wrap: wrap; /* Zorg ervoor dat items kunnen omvloeien op kleine schermen */
    margin: 0 auto; /* Centreer de container */
    width: 95%; /* De breedte van de container */
  }

  .scores-container {
    background-color: #f8f8f8; /* Achtergrondkleur van de container */
    display: flex;
    justify-content: center;
    flex-direction: column; /* Dit zorgt ervoor dat de items verticaal worden gestapeld */
  }
  
  .stats-item {
    flex-basis: 20%; /* Basis voor de flex items */
    text-align: center; /* Centreer de tekst horizontaal */
  }
  
  .stats-title {
    font-size: 0.9em; /* Aanpassen aan de grootte van de tekst */
    margin-bottom: 5px; /* Ruimte onder de titel */
  }
  
  .stats-value {
    font-size: 2.5em; /* Grotere tekst voor de waarde */
    font-weight: 500; /* Maak de waarde vetgedrukt  */
  }

  .success-rate .stats-value{
    font-size: 3.8em; /* Grotere tekst voor de waarde */
    font-weight:500; 
  }
  
  .success-rate {
    color: #00877F; /* Kleur voor het succespercentage */
  }

  .maturityScore {
    font-size: 2.5em;
    color: #002061;
    margin-top: 15px;    
    text-align: center; /* Centreer de tekst horizontaal */
    width: 100%; /* Neem de volledige breedte in beslag */
  }
  