/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.red {
  color: red;
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
}

/* Headings */
h1 {
  color: #002061;
  font-family: "Rubik", Sans-serif;
  font-size: 39px;
  font-weight: 500;
  line-height: 48px;
}

/* Gewone text */
body {
  font-family: "Nunito", Sans-serif;
}


/* Icons */
.icon {
  cursor: pointer;   
  opacity: 0.4;
  /* margin-right: 5px; space between icons */
}

.edit-icon i {
  color: green;
}

.assessment-card .icons {
  display: flex;
}

.assessment-card .icons .icon {
  cursor: pointer;   
  opacity: 1;  
  margin-right: 10px;   /*  Voegt wat ruimte toe tussen de iconen */
}

.assessment-card .icons .icon i {  
  color: rgb(255, 255, 255);
}

/* Hover effects for feedback */
.icon:hover {
  opacity: 0.2;
}

.bodyContent {
  padding-top: 80px;
}

/* Modals */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  color:#333;
  padding: 20px;
  border-radius: 5px;
  width: 50%; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); 
}

/* Knop om modal te sluiten */
.modal-content .closebutton {
  background-color: #c7c4c4; /* Rode kleur */
  color: white;
  border: none;
  border-radius: 5%;
  padding: 5px 10px;
  width: 30px;
  position: absolute; 
  top: 10px; 
  right: 10px; 
  cursor: pointer;
  font-weight: bold;
}

/* Hover-effect voor de sluitknop */
.modal-content button:hover {
  background-color: #da190b;
}

.modal-content label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
}

input,
select {
  margin-left: 10px;
  margin-top: 10px;
}

button {
  margin-top: 5px;
}

/* Human Core style buttons for links */
a.btn-custom {
  display: inline-block;  /* Om padding en andere block-eigenschappen te laten werken op het <a> element */
  background-color: #00877F;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;  /* Verwijdert de onderstreping van de link */
  font-family: "Nunito", Sans-serif;
  transition: background-color 0.4s;
}

a.btn-custom:hover {
  background-color: #002061;
}

/* Survey Maincolor */
.sv_main {
  background-color: #002061;
}

/* Human Core style forms */

.hc-custom-form {
  /* background-color: #fff; /* Witte achtergrond voor het formulier */
  /* padding: 20px; Ruimte binnenin het formulier */
  /* border-radius: 5px; Afgeronde hoeken voor het formulier */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); Een lichte schaduw voor wat diepte */
  max-width: 400px; /* Maximale breedte van het formulier */
  margin: 20px 0 20px 0 ;
  /* margin: 0 auto; Centreer het formulier op de pagina */
}

.hc-custom-form label {
  display: block; /* Laat elk label als een blokelement zien voor een nieuwe regel */
  margin-bottom: 10px; /* Ruimte onder elk label */
  margin-right: 10px; /* Ruimte onder elk label */
  margin-left: 10px;
  color: #333; /* Donkergrijze tekstkleur voor betere leesbaarheid */
}

.hc-custom-form input[type="text"],
.hc-custom-form input[type="number"],
.hc-custom-form input[type="password"] {
  font-family: "Nunito", Sans-serif;
  /* width: calc(100% - 20px); Volle breedte minus padding */
  padding: 10px; /* Comfortabele tekstpadding */
  margin-bottom: 10px; /* Ruimte onder elk tekstveld */
  border: 1px solid #ddd; /* Een subtiele rand */
  border-radius: 5px; /* Licht afgeronde hoeken voor de tekstvelden */
  box-sizing: border-box; /* Zorgt ervoor dat padding en border inbegrepen zijn in de totale breedte en hoogte */
}

.hc-custom-form button {
  background-color: #00877F; /* Een groene achtergrondkleur voor de verzendknop */
  color: white; /* Witte tekstkleur voor de knop */
  padding: 10px 20px; /* Verticale en horizontale padding voor de knop */
  margin-left: 10px;
  border: none; /* Geen rand voor de knop */
  border-radius: 8px; /* Licht afgeronde hoeken voor de knop */
  cursor: pointer; /* Handcursor op hover */
}

.hc-custom-form button:hover {
  background-color: #002061; /* Een iets donkerdere groen bij hover */
}

.hc-custom-form select {
  font-family: "Nunito", Sans-serif;
  /* width: 100%; Volle breedte van de container */
  padding: 10px; /* Comfortabele tekstpadding */
  margin-bottom: 10px; /* Ruimte onder het select veld */
  border: 1px solid #ddd; /* Een subtiele rand */
  border-radius: 5px; /* Licht afgeronde hoeken voor de select box */
  box-sizing: border-box; /* Zorgt ervoor dat padding en border inbegrepen zijn in de totale breedte en hoogte */
  -webkit-appearance: none; /* Verwijdert standaard stijl van WebKit browsers */
  -moz-appearance: none; /* Verwijdert standaard stijl van Firefox browsers */
  appearance: none; /* Verwijdert standaard stijl van de select box, waardoor deze volledig stijlbaar is */
  background-color: #fff; /* Achtergrondkleur van het select veld */
  cursor: pointer; /* Handcursor bij het hoveren over het select veld */
}

/* Pseudo-element voor custom dropdown pijl */
.hc-custom-form select::-ms-expand {
  display: none; /* Verwijdert de standaard dropdown pijl in IE */
}

/* Voorbeeld voor een custom dropdown pijl */
.hc-custom-form .select-wrapper {
  position: relative;
  display: inline-block;
}

.hc-custom-form .select-wrapper::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none; /* Zorgt ervoor dat klikken op de pijl het select veld opent */
}

.fi {
  cursor: pointer;
  width: 30px; /* of de gewenste grootte */
  height: 30px;
  border-radius: 50%;/*  Maakt de vlaggetjes rond */
  object-fit: cover; /* Zorgt ervoor dat de vlaggen netjes passen */
  margin-right: 4px; /* Voegt wat ruimte toe tussen de vlaggen */
  margin-left: 2px; /* Voegt wat ruimte toe tussen de vlaggen */
  border-style: solid;
  border-width: 1px;
  border-color: #a0a0a0;
}

.header-container {
  display: flex;
  justify-content: space-between; /* Zorgt ervoor dat de kinderen (h1 en LanguageSelector) worden gescheiden */
  align-items: center; /* Centreert de items verticaal */
}

.languageselector {
  background-color: #bb2c2c important;
}

.questionsTable {
  width: 100%;
  border-collapse: collapse;
}

.questionsTable th, .questionsTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.questionsTable th {
  background-color: #f2f2f2;
}

.questionsTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.questionsTable tr:hover {
  background-color: #eaeaea;
}

.icon {
  cursor: pointer;
  margin-right: 5px;
}

.edit-icon i, .delete-icon i {
  color: #4CAF50; /* Green */
}

.delete-icon i {
  color: #f44336; /* Red */
}

.org-row-bold {
  cursor: pointer;
  font-weight: bold;
}

.org-row {
  cursor: pointer;
}
