.assessment-card {
    background-color: #002061;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    width: 200px;
    height: 300px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: flex-start;
    color:white;
}

.assessment-container {
    display: flex;
    flex-wrap: wrap; 
    gap: 40px;
    /* justify-content: space-around;  */
}
  
